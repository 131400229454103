import http from '../clients/Axios'

export default class SaleingOrderService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          if (el === 'sl_no' || el === 'member') {
            filters_param =
              filters_param + '&' + el + '=' + filters[el].value.trim()
          } else {
            filters_param = filters_param + '&' + el + '=' + filters[el].value
          }
        }
      })
    }

    const res = await http.get(
      `/sales/paginate?page=${pageNumber}&size=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  // async paginate(param) {
  //   param = param ? param : ''
  //   const filter = {}
  //   filter.page = param.page
  //   filter.rows = param.rows
  //   filter.sortField = param.sortField
  //   filter.sortOrder = param.sortOrder

  //   filter.name = param.name

  //   const res = await http.post('/sales/paginate', JSON.stringify(filter))
  //   return res
  // }

  async get() {
    const res = await http.get('/sales')
    return res
  }

  async getBy(param) {
    param = param ? param : ''
    const res = await http.get(`/sales/receive${param}`)
    return res
  }

  async getById(param) {
    param = param ? param : ''
    const res = await http.get(`/sales/${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/sales', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/sales/${id}`, form)
    return res
  }

  async delete(sales) {
    const res = await http.delete(`/sales/${sales}`)
    return res
  }

  async print(id) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/sales/${id}/print?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async recommendation(
    // pageNumber,
    // pageSize = 10,
    sortField,
    sortOrder,
    supp_id
  ) {
    // pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    const res = await http.get(
      `/sales/recomendation?sort=${sort}&supp_id=${supp_id}`
    )

    return res.data
  }

  async recommendationPrint(sortField, sortOrder, supp_id) {
    const unixTime = Math.floor(Date.now() / 1000)
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    return http({
      url: `/sales/recomendation/print?t=${unixTime}&sort=${sort}&supp_id=${supp_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
